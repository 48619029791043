import React from "react";
import { UserInfoRow } from "./user-info-row";

interface Props {
  total: string;
  subscribed: string;
  free: string;
  newUsers: string;
}

export const UsersInfo: React.FC<Props> = ({
  free,
  total,
  newUsers,
  subscribed,
}) => {
  return (
    <div className="w-[100%] lg:w-[49%] h-[49%]">
      <h3 className="text-[#000000] text-[26px] font-bold">Users</h3>
      <div className="users-info border-[1px] border-[#B2B2B2] rounded-xl">
        <div className="w-full h-[50%] flex justify-between items-center border-b-[1px] border-[#000]">
          <UserInfoRow title="Total Users" count={total} />
          <div className="w-[1px] h-full bg-[#000]" />
          <UserInfoRow title="Subscribed Users" count={subscribed} />
        </div>
        <div className="w-full h-[50%] flex justify-between items-center">
          <UserInfoRow title="Free Users" count={free} />
          <div className="w-[1px] h-full bg-[#000]" />
          <UserInfoRow title="Number of New Users" count={newUsers} />
        </div>
      </div>
    </div>
  );
};
