import React, { useState } from "react";
import { SelectOption } from "../types";
import { ArrowDownIcon } from "../assets/icons/ArrowDownIcon";
import { CheckIcon } from "../assets/icons/CheckIcon";
import { ArrowUpIcon } from "../assets/icons/ArrowUp";
import { formattedDate } from "../utils/date";

interface Props {
  value?: SelectOption;
  options: SelectOption[];
  withDate?: boolean;
}

export const Select: React.FC<Props> = ({
  value,
  options,
  withDate = false,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleSelect = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="flex gap-2 z-50">
      <div className="w-[85px] h-[25px]">
        <div className="w-full h-full bg-[#D8D8D8] border-2 border-[#000] rounded-3xl px-2">
          <div
            onClick={toggleSelect}
            className="flex justify-between items-center cursor-pointer"
          >
            <span className="text-[14px]">
              {value ? value.label : "Select"}
            </span>
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </div>
        {isOpen ? (
          <div className="z-50 w-full bg-[#D8D8D8] border-[#000] mt-1 px-2">
            {options.map((item: SelectOption, index: number) => (
              <div
                key={item.value}
                onClick={toggleSelect}
                className={`flex justify-between items-center cursor-pointer py-1 ${
                  index !== options.length - 1
                    ? "border-b-2 border-b-[#000]"
                    : ""
                }`}
              >
                <span className="text-[14px]">{item.label}</span>
                {value && item.value === value.value ? <CheckIcon /> : <></>}
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      {withDate ? <span>{formattedDate}</span> : <></>}
    </div>
  );
};
