"use client";
import React, { ChangeEvent, FC, ReactNode } from "react";

interface TextFieldProps {
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  rightIcon?: ReactNode;
  placeholder?: string;
  type?: "text" | "email" | "password" | "textarea" | "number";
  isFilter?: boolean;
  mode?: "light" | "dark";
  textarea?: boolean;
  name: string;
  onClick?: () => void;
}

const TextField: FC<TextFieldProps> = ({
  value,
  name,
  onChange,
  onClick,
  disabled,
  placeholder,
  type = "text",
  textarea,
}) => {
  return (
    <div
      onClick={onClick}
      className={`relative py-4 px-5 flex items-center justify-between w-full rounded-[10px] border-2 border-lightGrey1 ${
        textarea ? "h-[150px]" : "h-[56px]"
      }`}
    >
      <input
        name={name}
        disabled={disabled || !!onClick}
        className="flex items-center justify-center w-full h-full bg-transparent"
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextField;
