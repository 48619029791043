import React from "react";
import { Select } from "../Select";
import Statistics from "./Statistics";
import { FILTERING_DATE_OPTIONS } from "../../constants/select-options";
import { UserStaticByDay } from "../../types";

interface Props {
  data: UserStaticByDay;
}

export const StatisticInfo: React.FC<Props> = ({ data }) => {
 

  return (
    <div className="w-[100%] lg:w-[49%] h-[49%]">
      <div className="flex flex-col md:flex-row justify-between items-center w-[90%] mb-2">
        <div className="flex gap-8 items-center">
          <h3 className="text-[#000000] text-[26px] font-bold">Game Counts</h3>
          <Select options={FILTERING_DATE_OPTIONS} />
        </div>
        <div className="flex gap-4">
          <div className="flex items-end gap-1">
            <div className="flex items-center">
              <div className="w-[12px] h-[12px] bg-[#0167E2] rounded-full" />
              <div className="w-[42px] h-[2px] bg-[#0167E2]" />
            </div>
            <span className="text-[12px] text-[#000]">New</span>
          </div>
          <div className="flex items-end gap-1">
            <div className="flex items-center">
              <div className="w-[12px] h-[12px] bg-[#20BD09] rounded-full" />
              <div className="w-[42px] h-[2px] bg-[#20BD09]" />
            </div>
            <span className="text-[12px] text-[#000]">Subscribed</span>
          </div>
          <div className="flex items-end gap-1">
            <div className="flex items-center">
              <div className="w-[12px] h-[12px] bg-[#FF0000] rounded-full" />
              <div className="w-[42px] h-[2px] bg-[#FF0000]" />
            </div>
            <span className="text-[12px] text-[#000]">Free</span>
          </div>
        </div>
      </div>
      <Statistics data={data} />
    </div>
  );
};
