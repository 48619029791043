import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../constants/api";
import { DailyContestData } from "../types";

interface UseDailyContestReturnData {
  data: DailyContestData[];
  error: string;
  isLoading: boolean;
}

interface UseDailyContestParams {
  date: string;
}

type UseDailyCotestDataType = (
  data: UseDailyContestParams
) => UseDailyContestReturnData;

export const useDailyContestData: UseDailyCotestDataType = ({
  date,
}): UseDailyContestReturnData => {
  const token = localStorage.getItem("accessToken");
  const [data, setData] = useState<DailyContestData[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const getDailyContestData = useCallback(() => {
    if (token) {
      fetch(`${API_URL}/api/v1/daily-contest/result/${date}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => setData(res))
        .catch(() => {
          setError("Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [date, token]);

  useEffect(() => {
    getDailyContestData();
  }, [getDailyContestData]);

  return { data, error, isLoading };
};
