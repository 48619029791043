import { FC } from "react";

interface Props {
  key: string;
  index: number;
  itemsLength: number;
  email: string;
  username: string;
  score: number;
  chipCount: number;
  totalWins: string;
}

export const ContestTableItem: FC<Props> = ({
  key,
  index,
  email,
  username,
  score,
  chipCount,
  totalWins,
  itemsLength,
}) => {
  return (
    <div
      key={key}
      className={`w-full flex ${
        (index + 1) % 2 ? "bg-[#FDFEFC]" : "bg-[#DADBD9]"
      } ${index !== itemsLength - 1 ? "border-b-[1px] border-[#000]" : ""}`}
    >
      <span className="px-2 text-[15px] w-[30%] border-r-[1px] border-[#000] truncate">
        {email}
      </span>
      <span className="px-2 text-[15px] w-[25%] border-r-[1px] border-[#000]">
        {username}
      </span>
      <span className="text-center px-2 text-[15px] w-[15%] border-r-[1px] border-[#000]">
        {score}
      </span>
      <span className="text-center px-2 text-[15px] w-[15%] border-r-[1px] border-[#000]">
        ${chipCount}
      </span>
      <span className="text-center px-2 text-[15px] w-[15%]">{totalWins}</span>
    </div>
  );
};
