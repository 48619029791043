import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../constants/api";
import { GameStatistic } from "../types";

interface UseGetDashboardReturnData {
  data: GameStatistic[];
  error: string;
  isLoading: boolean;
}

type UseGetDashboardDataType = () => UseGetDashboardReturnData;

export const useGameStatisticData: UseGetDashboardDataType =
  (): UseGetDashboardReturnData => {
    const token = localStorage.getItem("accessToken");
    const [data, setData] = useState<GameStatistic[]>([]);
    const [error, setError] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);

    const getGameStatisticData = useCallback(() => {
      if (token) {
        fetch(`${API_URL}/api/v1/daily-contest/gameStatistic/annual`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((res) => setData(res))
          .catch(() => {
            setError("Something went wrong");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, [token]);

    useEffect(() => {
      getGameStatisticData();
    }, [getGameStatisticData]);

    return { data, error, isLoading };
  };
