import React, { useMemo } from "react";
import { GameInfoRow } from "./game-info-row";
import { useGameStatisticData } from "../../hooks/useGameStatisticData";
import { GameTypes } from "../../types";

const GAME_INFO: Record<GameTypes, any> = {
  [GameTypes.DAILY_CONTEST]: {
    title: "Daily Contest",
    count: 0,
  },
  [GameTypes.SINGLE_PLAYER]: {
    title: "Single Player",
    count: 0,
  },
  [GameTypes.ONLINE_MULTIPLAYER]: {
    title: "Online Multiplayer",
    count: 0,
  },
  [GameTypes.PLAY_WITH_FRIENDS]: {
    title: "Play with Friends",
    count: 0,
  },
  [GameTypes.PASS_AND_PLAY]: {
    title: "Play with Friends",
    count: 0,
  },
};

export const GameInfo: React.FC = () => {
  const { data } = useGameStatisticData();

  const gameInfoData = useMemo(() => {
    const obj = { ...GAME_INFO } as any;
    if (data?.length) {
      data.forEach((item) => {
        obj[item.gameStatistic_type] = {
          ...GAME_INFO[item.gameStatistic_type],
          count: item.totalGames,
        };
      });
    }
    return obj;
  }, [data]);

  return (
    <div className="w-[100%] lg:w-[49%] h-[49%]">
      <h3 className="text-[#000000] text-[26px] font-bold">Game Counts</h3>
      <div className="game-info border-[1px] border-[#B2B2B2] rounded-xl">
        <div className="w-full h-[50%] flex justify-between items-center border-b-[1px] border-[#000]">
          <GameInfoRow
            title={gameInfoData?.[GameTypes.DAILY_CONTEST]?.title}
            count={gameInfoData?.[GameTypes.DAILY_CONTEST]?.count ?? 0}
          />
          <div className="w-[1px] h-full bg-[#000]" />
          <GameInfoRow
            title={gameInfoData?.[GameTypes.SINGLE_PLAYER]?.title}
            count={gameInfoData?.[GameTypes.SINGLE_PLAYER]?.count}
          />
        </div>
        <div className="w-full h-[50%] flex justify-between items-center">
          <GameInfoRow
            title={gameInfoData?.[GameTypes.ONLINE_MULTIPLAYER]?.title}
            count={gameInfoData?.[GameTypes.ONLINE_MULTIPLAYER]?.count}
          />
          <div className="w-[1px] h-full bg-[#000]" />
          <GameInfoRow
            title={gameInfoData?.[GameTypes.PLAY_WITH_FRIENDS]?.title}
            count={gameInfoData?.[GameTypes.PLAY_WITH_FRIENDS]?.count}
          />
        </div>
      </div>
    </div>
  );
};
