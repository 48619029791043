import React from "react";
import { UsersInfo } from "../components/user-info/UsersInfo";
import { GameInfo } from "../components/game-info/GameInfo";
import { ContestInfo } from "../components/contest-info/ContestInfo";
import { StatisticInfo } from "../components/statistics";
import { useGetDashboardData } from "../hooks/useGetDashboardData";

const Dashboard: React.FC = () => {
  const { data } = useGetDashboardData();

  if (!data) {
    return <></>;
  }

  return (
    <div className="flex w-full h-[100vh] justify-center items-center p-2">
      <div className="flex flex-wrap w-full h-full gap-4">
        <ContestInfo />
        <UsersInfo
          free={data?.totalCountUsers?.countOfFreeUsers ?? []}
          newUsers={data?.totalCountNewUsers?.count ?? []}
          total={data?.totalCountUsers?.totalCountOfUsers ?? []}
          subscribed={data?.totalCountUsers?.countOfSubscribedUsers ?? []}
        />
        <StatisticInfo data={data.userStaticByDay} />
        <GameInfo />
      </div>
    </div>
  );
};

export default Dashboard;
