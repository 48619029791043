// LineChart.js
import React, { FC } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";
import { UserStaticByDay, UserStatisticData } from "../../types";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

interface Props {
  data: UserStaticByDay;
}

const Statistics: FC<Props> = ({ data }) => {
  function combineCounts(statistics: UserStatisticData[]) {
    const result: any = {};

    statistics.forEach((entry) => {
      const date = entry.data.split("T")[0];
      const count = Number(entry.count);

      if (date in result && result[date]) {
        result[date] += count;
      } else {
        result[date] = count;
      }
    });

    return Object.keys(result).map((date) => ({
      date,
      count: result[date],
    }));
  }

  const newUsersStatistic = combineCounts(data?.newUsersStatistic ?? []);
  const subscribedUsersStatistic = combineCounts(
    data?.subscribedUsersStatistic ?? []
  );
  const freeUsersStatistic = combineCounts(data?.freeUsersStatistic ?? []);

  const lineData = {
    labels: newUsersStatistic.map((item) => item.date),
    datasets: [
      {
        label: "Series 1",
        data: freeUsersStatistic.map((item) => item.count),
        borderColor: "#FF0000",
        backgroundColor: "#FF0000",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Series 2",
        data: subscribedUsersStatistic.map((item) => item.count),
        borderColor: "#20BD09",
        backgroundColor: "#20BD09",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Series 3",
        data: newUsersStatistic.map((item) => item.count),
        borderColor: "#0167E2",
        backgroundColor: "#0167E2",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Custom Line Chart",
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Date",
        },
      },
      y: {
        title: {
          display: false,
          text: "Value",
        },
        min: 0,
        max: 50,
      },
    },
  };

  return (
    <Line
      style={{
        backgroundColor: "#F3F3F3",
        width: "100%",
        height: "100%",
      }}
      data={lineData}
      options={options}
    />
  );
};

export default Statistics;
