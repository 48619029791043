import { RefObject, useCallback, useEffect } from "react";

export const useClickOutsideListener = (
  ref: RefObject<HTMLElement>,
  callback: VoidFunction,
  listen: boolean = true
) => {
  const listener = useCallback(
    (event: MouseEvent) => {
      const isOutside =
        ref.current && !ref.current.contains(event.target as Node);
      if (isOutside) {
        callback();
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    if (listen) {
      document.addEventListener("mousedown", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }
    document.removeEventListener("mousedown", listener);
  }, [listen, listener]);
};
