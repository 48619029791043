import { useCallback, useState } from "react";
import { API_URL } from "../constants/api";
import { AuthRequestBody } from "../types";

interface UseAuthReturnData {
  credentials: AuthRequestBody;
  error: string;
  isLoading: boolean;
  handleLogin: () => void;
  handleChangeCredentials: (value: string, name: string) => void;
}

type UseAuthType = (
  handleSetToken: (value: string) => void
) => UseAuthReturnData;

export const useAuth: UseAuthType = (handleSetToken): UseAuthReturnData => {
  const [credentials, setCredentials] = useState<AuthRequestBody>({
    username: "admin123@gmail.com",
    password: "Admin123!",
  });

  const [error, setError] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleChangeCredentials = useCallback(
    (value: string, name: string): void => {
      setCredentials((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleLogin = useCallback((): void => {
    setLoading(true);
    setError("");
    fetch(`${API_URL}/api/v1/auth/sign-in`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.tokens && res.tokens.accessToken) {
          handleSetToken(res.tokens.accessToken);
          localStorage.setItem("accessToken", res.tokens.accessToken);
          localStorage.setItem("user", JSON.stringify(res));
        }
      })
      .catch(() => {
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [credentials, handleSetToken]);

  return {
    error,
    isLoading,
    credentials,
    handleLogin,
    handleChangeCredentials,
  };
};
