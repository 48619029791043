export enum GameTypes {
  DAILY_CONTEST = "DAILY_CONTEST",
  ONLINE_MULTIPLAYER = "ONLINE_MULTIPLAYER",
  PASS_AND_PLAY = "PASS_AND_PLAY",
  SINGLE_PLAYER = "SINGLE_PLAYER",
  PLAY_WITH_FRIENDS = "PLAY_WITH_FRIENDS",
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface InfoData {
  title: string;
  count: number;
}

export interface AuthRequestBody {
  username: string;
  password: string;
}

export interface AuthTokensResponse {
  accessToken: string;
  refreshToken: string;
}

export interface AuthResponseBody {
  id: string;
  username: string;
  email: string;
  tokens: AuthTokensResponse;
  isSubscribed: false;
}

export interface UserStatisticData {
  data: string;
  count: number;
}

export interface UserStaticByDay {
  newUsersStatistic: UserStatisticData[];
  subscribedUsersStatistic: UserStatisticData[];
  freeUsersStatistic: UserStatisticData[];
}

export interface TotalCountUsers {
  totalCountOfUsers: string;
  countOfSubscribedUsers: string;
  countOfFreeUsers: string;
}

export interface TotalCountNewUsers {
  count: string;
}

export interface DashboardDataResponse {
  dailyContestStatistic: any[];
  gameStatic: any[];
  userStaticByDay: UserStaticByDay;
  totalCountUsers: TotalCountUsers;
  totalCountNewUsers: TotalCountNewUsers;
}

export interface GameStatistic {
  gameStatistic_type: GameTypes;
  totalGames: string;
}

export interface DailyContestData {
  chipCount: number;
  createdAt: string;
  id: string;
  score: number;
  totalWins: string;
  user: DailyContestUser;
}

export interface DailyContestUser {
  email: string;
  id: string;
  username: string;
}
