import React, { ChangeEvent } from "react";
import TextField from "../components/textfield";
import { useAuth } from "../hooks/useAuth";
import Button from "../components/button";

interface Props {
  handleSetToken: (value: string) => void;
}

const Login: React.FC<Props> = ({ handleSetToken }) => {
  const { credentials, handleLogin, handleChangeCredentials } =
    useAuth(handleSetToken);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;
      handleChangeCredentials(value, name);
    }
  };

  return (
    <main className="login flex w-screen h-screen justify-center items-center">
      <main className="flex flex-col gap-[56px] items-center">
        <div className="flex flex-col p-8 bg-lightGrey rounded-[20px] justify-center gap-8 w-[375px] sm:w-[520px] bg-white">
          <span className="text-2xl font-medium leading-7 text-dark not-italic self-center">
            Welcome to Admin Dashboard
          </span>
          <TextField
            name="username"
            type="text"
            placeholder="Username"
            value={credentials.username}
            onChange={handleInputChange}
          />
          <TextField
            name="password"
            type="password"
            placeholder="Password"
            value={credentials.password}
            onChange={handleInputChange}
          />
          <Button
            disabled={!credentials.username || !credentials.password}
            text="Sign In"
            onClick={handleLogin}
          />
        </div>
      </main>
    </main>
  );
};

export default Login;
