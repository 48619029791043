import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../constants/api";
import { DashboardDataResponse } from "../types";

interface UseGetDashboardReturnData {
  data: DashboardDataResponse | null;
  error: string;
  isLoading: boolean;
}

type UseGetDashboardDataType = () => UseGetDashboardReturnData;

export const useGetDashboardData: UseGetDashboardDataType =
  (): UseGetDashboardReturnData => {
    const token = localStorage.getItem("accessToken");
    const [data, setData] = useState<DashboardDataResponse | null>(null);
    const [error, setError] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);

    const getData = useCallback(() => {
      if (token) {
        setLoading(true);
        setError("");
        fetch(`${API_URL}/api/v1/daily-contest/getAllStatic/monthly`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((res) => setData(res))
          .catch(() => {
            setError("Something went wrong");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, [token]);

    useEffect(() => {
      getData();
    }, [getData]);

    return { data, error, isLoading };
  };
