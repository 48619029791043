import React, { useMemo, useState } from "react";
import { ContestTable } from "./contest-table/contest-table";
import CalendarPicker from "../calendar-picker";
import { useDailyContestData } from "../../hooks/useDailyContestData";
import moment from "moment";

export const ContestInfo: React.FC = () => {
  const [date, setDate] = useState(new Date());

  const formattedDate = useMemo(() => {
    return {
      requestDate: moment(date).format("YYYY-MM-DD"),
      showDate: moment(date).format("M/D/YYYY"),
    };
  }, [date]);

  const { data } = useDailyContestData({ date: formattedDate.requestDate });

  const onChangeDate = (value: Date) => {
    setDate(value);
  };

  return (
    <div className="w-[100%] lg:w-[49%] h-[49%]">
      <h3 className="text-[#000000] text-[26px] font-bold">Contest Scores</h3>
      <div className="contest-info border-[1px] border-[#B2B2B2] rounded-xl px-3 overflow-y-auto overflow-x-auto">
        <div className="mb-4 mt-4">
          <CalendarPicker value={date} onChange={onChangeDate} />
        </div>
        <ContestTable data={data} />
      </div>
    </div>
  );
};
