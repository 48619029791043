import React from "react";

export const ContestTableHeader: React.FC = () => {
  return (
    <div className="w-full flex border-b-[1px] border-[#000]">
      <span className="px-2 text-[15px] w-[30%] border-r-[1px] border-[#000]">
        Email
      </span>
      <span className="px-2 text-[15px] w-[25%] border-r-[1px] border-[#000]">
        User Name
      </span>
      <span className="text-center px-2 text-[15px] w-[15%] border-r-[1px] border-[#000]">
        Final Score
      </span>
      <span className="text-center px-2 text-[15px] w-[15%] border-r-[1px] border-[#000]">
        Chip Count
      </span>
      <span className="text-center px-2 text-[15px] w-[15%]">TotalWins</span>
    </div>
  );
};
