import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  isAuth: boolean;
  children: ReactNode;
}

const PrivateRoute: React.FC<Props> = ({ isAuth, children }) => {
  return isAuth ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
