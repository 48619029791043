import React from "react";
import { ContestTableItem } from "./contest-table-item";
import { DailyContestData } from "../../../types";

interface Props {
  data: DailyContestData[];
}

export const ContestTableBody: React.FC<Props> = ({ data }) => {
  return (
    <div className="w-full">
      {data.length ? (
        data.map((item, index) => (
          <ContestTableItem
            key={item.id}
            index={index}
            itemsLength={data.length}
            email={item.user.email}
            username={item.user.username}
            chipCount={item.chipCount}
            score={item.score}
            totalWins={item.totalWins}
          />
        ))
      ) : (
        <div className="w-full h-full justify-center items-center flex">
          No items founds
        </div>
      )}
    </div>
  );
};
