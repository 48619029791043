import React from "react";

interface Props {
  title: string;
  count: number;
}

export const GameInfoRow: React.FC<Props> = ({ title, count }) => {
  return (
    <div className="relative flex w-[48%] h-[100%] justify-center items-center">
      <span className="text-[12px] absolute top-2 left-1">{title}</span>
      <span className="text-[55px] font-bold">{count}</span>
    </div>
  );
};
