import React, { FC, useMemo, useRef, useState } from "react";
import moment from "moment";
import { ArrowDownIcon } from "../../assets/icons/ArrowDownIcon";
import { ArrowUpIcon } from "../../assets/icons/ArrowUp";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { useClickOutsideListener } from "../../hooks/useClickOutsideListener";

const formatCaption = (day: any) => {
  return day.toString().slice(0, 3);
};

interface Props {
  label?: string;
  disabled?: boolean;
  value?: any;
  placeholder?: string;
  onChange: (value?: any) => void;
}

const CalendarPicker: FC<Props> = ({
  label,
  value,
  placeholder = "All",
  onChange,
}) => {
  const bodyRef = useRef(null);

  const [isCalendarOpened, setIsCalendarOpened] = useState(false);

  useClickOutsideListener(bodyRef, () => setIsCalendarOpened(false));

  const renderValue = useMemo(() => {
    if (!value || (value && !(value instanceof Date) && !value.from)) {
      return placeholder;
    }
    if (value instanceof Date) {
      return moment(value).format("DD/MM/YY");
    }
    return `${value.from ? moment(value.from).format("DD/MM/YY") : ""}${
      value.to ? `-${moment(value.to).format("DD/MM/YY")}` : ""
    }`;
  }, [value, placeholder]);

  return (
    <div
      onClick={() => setIsCalendarOpened(true)}
      ref={bodyRef}
      className="flex gap-2 z-50 relative"
    >
      <div className=" w-[85px] h-[25px]">
        <div className="w-full h-full bg-[#D8D8D8] border-2 border-[#000] rounded-3xl px-2">
          <div className="flex justify-between items-center cursor-pointer">
            <span className="text-[14px]">{renderValue ?? "Select"}</span>
            {isCalendarOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </div>
      </div>
      <span>{renderValue}</span>
      {isCalendarOpened && (
        <div
          className={`absolute border-[1px] border-[#000] rounded-lg top-16  z-[100]`}
        >
          <DayPicker
            mode="single"
            selected={value}
            formatters={{ formatWeekdayName: formatCaption }}
            className="flex bg-white justify-center text-[17px] font-medium rounded-md overflow-hidden !m-[0px]"
            modifiersStyles={{
              today: { color: "#873DE6", backgroundColor: "#F3EAFF" },
              selected: { backgroundColor: "#873DE6", color: "white" },
            }}
            styles={{
              head_cell: { color: "#A8A1B1" },
              nav_icon: { color: "#873DE6" },
              caption_label: { fontSize: "17px" },
              head_row: { fontSize: "12px" },
            }}
            onSelect={(data: any) => {
              onChange(data);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarPicker;
