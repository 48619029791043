import React from "react";
import { ContestTableHeader } from "./contest-table-header";
import { ContestTableBody } from "./contest-table-body";
import { DailyContestData } from "../../../types";

interface Props {
  data: DailyContestData[];
}

export const ContestTable: React.FC<Props> = ({ data }) => {
  return (
    <div className="border-[1px] border-[#000] my-4">
      <ContestTableHeader />
      <ContestTableBody data={data} />
    </div>
  );
};
