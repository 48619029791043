import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import { useCallback, useLayoutEffect, useState } from "react";
import PublicRoute from "./PublicRoute";

const AppRouter = () => {
  const [token, setToken] = useState<string>("");

  useLayoutEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setToken(token);
    }
  }, []);

  const handleSetToken = useCallback((value: string) => {
    setToken(value);
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute isAuth={!!token}>
            <Login handleSetToken={handleSetToken} />
          </PublicRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute isAuth={!!token}>
            <Dashboard />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;
