import React, { FC, ReactNode } from "react";

interface ButtonsProps {
  disabled?: boolean;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  text?: string;
  icon?: ReactNode;
  isIconButton?: boolean;
}

const Button: FC<ButtonsProps> = ({
  text,
  disabled = false,
  type = "button",
  onClick,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex justify-center items-center h-[48px] text-[15px] bg-[#873DE6] text-white w-full py-3 px-6 rounded-xl ${
        disabled ? "opacity-40" : "opacity-100"
      }`}
    >
      {text}
    </button>
  );
};

export default Button;
